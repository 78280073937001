import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"

const Company = () => {
    return (
        <div className="container py-3 py-lg-4">
            <div className="row g-0 mb-3 ">
                <div className="col border-bottom border-1 border-dark">
                    <h1>Bulk Logistics</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 mb-3 mb-md-0 ">
                    <div className="card-body h-100 gray-bg d-flex flex-column" style={{ padding: '16px' }}>
                        <h2 className="card-title" style={{ marginBottom: '8px' }}>About us</h2>
                        <p className="card-text">
                            Delta Corp Bulk Logistics facilitates international trade between worlds major economies as well as
                            emerging markets. Connecting end users to producers and miners, we extract value along the supply chain.
                        </p>
                        <p>
                            It has been our goal from the onset to make a difference in the way drybulk and tanker ocean freight is
                            being traded.
                        </p>
                        <p>
                            In addition to sea transport we offer various road transport solutions through our company{" "}
                            <a
                                className="link-dark middle-dark"
                                href="https://www.vishwaacarriers.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Vishwaa Carriers
                            </a>
                            .
                        </p>

                        <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                            <Link to="/bulk-logistics/company/about-us" className="text-dark">
                                Learn more 
                                <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="card border-0 h-100 card-product ">
                                <div className="card-body d-flex flex-column">
                                    <h2 className="text-light  ">Management</h2>
                                    <p className="text-light">
                                        A talented team of professionals that are dedicated to delivering excellence in every aspect of
                                        the business.
                                    </p>
                                    <div className="text-end border-top border-2 border-light pt-3 mt-auto">
                                        <Link to="/bulk-logistics/company/management" className="text-light">
                                        Learn more
                                        <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body  gray-bg d-flex flex-column" style={{ padding: '16px' }}>
                                <div className="row">
                                    <div className="col-12 mb-2 mb-lg-0  col-lg-4 text-center ">
                                    <svg width="3.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="anchor" class="svg-inline--fa fa-anchor fa-w-18 fa-fw fa-3x mb-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M571.515 331.515l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h44.268C453.531 417.326 380.693 456.315 312 462.865V216h60c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-60v-11.668c32.456-10.195 56-40.512 56-76.332 0-44.183-35.817-80-80-80s-80 35.817-80 80c0 35.82 23.544 66.138 56 76.332V168h-60c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h60v246.865C195.192 456.304 122.424 417.176 102.762 352h44.268c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.03 67.029C-3.074 339.074 2.28 352 12.971 352h40.284C73.657 451.556 181.238 512 288 512c113.135 0 215.338-65.3 234.745-160h40.284c10.691 0 16.045-12.926 8.486-20.485zM288 48c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"></path></svg>
                                        <h5>Anchor investors</h5>
                                    </div>

                                    <div className="col-12 mb-2 mb-lg-0 col-lg-4 text-center ">
                                    <svg width="3.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="users" class="svg-inline--fa fa-users fa-w-20 fa-fw fa-3x mb-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zm396.4 210.9c-27.5-40.8-80.7-56-127.8-41.7-14.2 4.3-29.1 6.7-44.7 6.7s-30.5-2.4-44.7-6.7c-47.1-14.3-100.3.8-127.8 41.7-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c.2-23.8-7-45.9-19.4-64.3zM464 432H176v-44.8c0-36.4 29.2-66.2 65.4-67.2 25.5 10.6 51.9 16 78.6 16 26.7 0 53.1-5.4 78.6-16 36.2 1 65.4 30.7 65.4 67.2V432zm92-176h-24c-17.3 0-33.4 5.3-46.8 14.3 13.4 10.1 25.2 22.2 34.4 36.2 3.9-1.4 8-2.5 12.3-2.5h24c19.8 0 36 16.2 36 36 0 13.2 10.8 24 24 24s24-10.8 24-24c.1-46.3-37.6-84-83.9-84zm-236 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-176c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM154.8 270.3c-13.4-9-29.5-14.3-46.8-14.3H84c-46.3 0-84 37.7-84 84 0 13.2 10.8 24 24 24s24-10.8 24-24c0-19.8 16.2-36 36-36h24c4.4 0 8.5 1.1 12.3 2.5 9.3-14 21.1-26.1 34.5-36.2z"></path></svg>
                                        <h5>Proven Management</h5>
                                    </div>

                                    <div className="col-12 col-lg-4 text-center ">
                                    <svg width="3.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="gem" class="svg-inline--fa fa-gem fa-w-18 fa-fw fa-3x mb-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M464 0H112c-4 0-7.8 2-10 5.4L2 152.6c-2.9 4.4-2.6 10.2.7 14.2l276 340.8c4.8 5.9 13.8 5.9 18.6 0l276-340.8c3.3-4.1 3.6-9.8.7-14.2L474.1 5.4C471.8 2 468.1 0 464 0zm-19.3 48l63.3 96h-68.4l-51.7-96h56.8zm-202.1 0h90.7l51.7 96H191l51.6-96zm-111.3 0h56.8l-51.7 96H68l63.3-96zm-43 144h51.4L208 352 88.3 192zm102.9 0h193.6L288 435.3 191.2 192zM368 352l68.2-160h51.4L368 352z"></path></svg>
                                        <h5>Well Capitalised</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company
