import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroBgImage from "../../components/bulk-logistics/index/hero-image"
import Company from "../../components/bulk-logistics/index/company"
import Services from "../../components/bulk-logistics/index/services"
import LatestNews from "../../components/bulk-logistics/index/latest-news"

const IndexPage = ({ location }) => (
    <Layout location={location}>
        <Seo title="Home" />
        <HeroBgImage />
        <Company />
        <Services />
        <LatestNews />
    </Layout>
)

export default IndexPage
